import React from 'react';

type SliderItemProps = {
    img: string
    url: string
    height : string
    width : string
    alt? : string
    className? : string
    style? : React.CSSProperties
}

const SliderItem = ({className, style, img, url, height, width, alt} : SliderItemProps): React.JSX.Element => { 
    return (
        <div className="slide">
            <a target='_blank' href={url!}><img src={img} height={height} width={width} alt={alt} className="slider-link" style={{maxHeight:'100px'}} /></a>
        </div>
     
    );
};

export default SliderItem;