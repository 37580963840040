import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { MapPin, Mail, Phone, Linkedin } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import Section from '../Section';
import { routes, CustomRouteObject } from '../../../AppRoutes';
import Cookie from '../Cookie';

import "./footer.css";
import { FooterReunion, FooterFlowlyColor, FooterFlowlyWhite } from './../../../assets/img/footer';

const Footer = (): React.JSX.Element => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const date = new Date();
    let loops = 0, loops_second = 0;
    
    return (<>
        <Section className='flowly-footer'>
            <Row className='py-3'>
                <Col xs={0} lg={1}></Col>
                <Col xs={12} lg={3} className='mt-3 mt-lg-0'>
                    <div className='logo-container'>
                        <div className="smedia-footer">
                            <a className='mtb-auto' target='_blank' href="/">
                                <img className="colored-png" src={FooterFlowlyWhite} height={'35px'} />
                            </a>
                        </div>
                        <div className="smedia-footer">
                            <a className='mtb-auto' target='_blank' href="https://marquelareunion.re/">
                                <img className="colored-png" src={FooterReunion} height={'30px'} />
                            </a>
                        </div>
                        <div className="smedia-footer">
                            <h6 className='d-none'>{t('Footer.FollowFlowly')}</h6>
                            <ul className='list-link mtb-auto'>
                                <li>
                                    <div className="footer-social-media text-center">
                                        <a target='_blank' href="https://www.linkedin.com/company/flowly1/">
                                            <Linkedin color="#FFF" size={16} className='icon' />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col xs={0} lg={1}></Col>
                <Col xs={12} lg={3} className='mt-3 mt-lg-0 footer-dnone'>
                    <h6>Flowly</h6>
                    <Row>
                        <Col xs={3} lg={6} className='mt-lg-0'>
                            <ul className='list-link flex-column'>
                                {
                                    routes[0].children !== undefined && routes[0].children.map((el: CustomRouteObject, index: number) => {
                                        ++loops;
                                        if (loops > 1 && loops <= 4) {
                                            if (el.visible !== undefined && !el.visible) return;
                                            let elTitle = el.title === undefined ? "" : t(el.title)
                                            let tempPath = "";
                                            if (el.path !== undefined) tempPath = el.path;
                                            return <li key={index}><a className='' onClick={() => { navigate(tempPath) }}>{elTitle}</a></li>
                                        }
                                    })
                                }
                            </ul>
                        </Col>
                        <Col xs={3} lg={6} className='mt-lg-0'>
                            <ul className='list-link flex-column'>
                                {
                                    routes[0].children !== undefined && routes[0].children.map((el: CustomRouteObject, index: number) => {
                                        ++loops_second;
                                        if (loops_second > 4) {
                                            if (el.visible !== undefined && !el.visible) return;
                                            let elTitle = el.title === undefined ? "" : t(el.title)
                                            let tempPath = "";
                                            if (el.path !== undefined) tempPath = el.path;
                                            return <li key={index}><a className='' onClick={() => { navigate(tempPath) }}>{elTitle}</a></li>
                                        }
                                    })
                                }
                            </ul>
                        </Col>
                    </Row>
                </Col>
                <Col xs={0} lg={1}></Col>
                <Col xs={12} lg={3}>
                    <Row className="footer-contact">
                        <Col xs={12} className='mt-3 mt-lg-0'>
                            <h6 className="footer-dnone">{t('Footer.Contact')}</h6>
                            <ul className='list-link flex-column'>
                                <li>
                                    <Mail size={16}/>
                                    <p> contact@flowly.re</p>
                                </li>
                                <li>
                                    <MapPin size={16}/>
                                    <p> 14 rue Jules Thirel, 97460, St-Paul, RE</p>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={12} className='mt-3'>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Section>
        <Section className='flowly-footer-bottom'>
            <Row>
                <Col xs={12} className='text-center'>
                    <p>&copy; {date.getFullYear()} Flowly SAS | <a className='link' onClick={()=>{navigate('/legal')}} >{t('Footer.LegalLabel')}</a> | <a className='link' onClick={()=>{navigate('/cookie')}}>{t('Footer.CookieLabel')}</a></p>
                </Col>
            </Row>
            <Cookie activate={true}/>
        </Section>

    </>);
};

export default Footer;
