import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

import Section from '../shared/Section';
import './Privacy.css'
import { DataBase, Device, Chain, CompleteData, Folder, FileLock, ClientInfo, TransfertData } from './../../assets/img/privacy';

const Privacy = (): React.JSX.Element => {

    const { t } = useTranslation();

    return <>
        <Section className="section-privacy">
            <Row className='text-center'>
                <Col>
                    <h2 dangerouslySetInnerHTML={
                        { __html: t('Pages.Privacy.Title') === undefined ? "" : t('Pages.Privacy.Title') }
                    } />
                    <p dangerouslySetInnerHTML={
                        { __html: t('Pages.Privacy.SubTitle') === undefined ? "" : t('Pages.Privacy.SubTitle') }
                    } />
                </Col>
            </Row>
        </Section>

        <Section className='section-privacy-bg section-privacy'>
            <Row>
                <Col md={12}  lg={6}>
                    <Row className='align-items-center privacy-card'>
                        <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                            <img alt={t('Pages.Privacy.NoCollect.AltImg')} src={Folder} />
                        </Col>
                        <Col md={9}  className='text-center text-md-start'>
                            <p>{t('Pages.Privacy.NoCollect.Description')}</p>
                        </Col>
                    </Row>
                    <Row className='align-items-center privacy-card'>
                        <Col xs={12}  md={3} className="image-elements pt-3 pt-md-0">
                            <img alt={t('Pages.Privacy.ClientInfo.AltImg')} src={ClientInfo} />
                        </Col>
                        <Col md={9} className='text-center text-md-start'>
                            <p>{t('Pages.Privacy.ClientInfo.Description')}</p>
                        </Col>
                    </Row>
                    <Row className='align-items-center privacy-card'>
                        <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                            <img alt={t('Pages.Privacy.Device.AltImg')} src={Device} />
                        </Col>
                        <Col md={9} className='text-center text-md-start'>
                            <p>{t('Pages.Privacy.Device.Description')}</p>
                        </Col>
                    </Row>
                    <Row className='align-items-center privacy-card'>
                        <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                            <img alt={t('Pages.Privacy.TransfertData.AltImg')} src={TransfertData} />
                        </Col>
                        <Col md={9} className='text-center text-md-start'>
                            <p>{t('Pages.Privacy.TransfertData.Description')}</p>
                        </Col>
                    </Row>
                </Col>
                <Col md={12} lg={6}>
                    <Row className='align-items-center privacy-card'>
                        <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                            <img alt={t('Pages.Privacy.DataBase.AltImg')} src={DataBase} />
                        </Col>
                        <Col md={9} className='text-center text-md-start'>
                            <p>{t('Pages.Privacy.DataBase.Description')}</p>
                        </Col>
                    </Row>
                    <Row className='align-items-center privacy-card'>
                        <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                            <img alt={t('Pages.Privacy.FileLock.AltImg')} src={FileLock} />
                        </Col>
                        <Col md={9} className='text-center text-md-start'>
                            <p>{t('Pages.Privacy.FileLock.Description')}</p>
                        </Col>
                    </Row>
                    <Row className='align-items-center privacy-card'>
                        <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                            <img alt={t('Pages.Privacy.CompleteData.AltImg')} src={CompleteData} />
                        </Col>
                        <Col md={9} className='text-center text-md-start'>
                            <p>{t('Pages.Privacy.CompleteData.Description')}</p>
                        </Col>
                    </Row>
                    <Row className='align-items-center privacy-card'>
                        <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                            <img alt={t('Pages.Privacy.Chain.AltImg')} src={Chain} />
                        </Col>
                        <Col md={9} className='text-center text-md-start'>
                            <p>{t('Pages.Privacy.Chain.Description')}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Section>
    </>

}
export default Privacy;