import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Section from '../shared/Section';
import './mentions-legales.css';


const MentionsLegales = (): React.JSX.Element => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    return <>
        <Section className="legal-wrapper justified-text">
            <Row>
                <Col xs={12} className="text-center">

                    <h2 dangerouslySetInnerHTML={{ __html: t('Pages.Legal.Title')! }}></h2>

                </Col>
            </Row>

            <Row>
                <Col xs={0} sm={0} md={0} lg={2}></Col>
                <Col xs={12} sm={12} md={12} lg={8} className="justified-text" >
                    <p  dangerouslySetInnerHTML={{ __html: t('Pages.Legal.MentionText')! }}>
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Legal.Contact')! }}>
                    </p>
                    <p>{t('Pages.Legal.CommunicationExecutor')! }</p>
                    
                </Col>
                <Col xs={0} sm={0} md={0} lg={2}></Col>
            </Row>
            <Row>
                <Col xs={0} sm={0} md={0} lg={2}></Col>

                <Col xs={12} sm={12} md={12} lg={8} >
                    <h5 dangerouslySetInnerHTML={{ __html: t('Pages.Legal.ContactTitle')! }}></h5>
                </Col>
            </Row>
            <Row>
                <Col xs={0} sm={0} md={0} lg={2}></Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <p dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleContact1')! }}>
                   </p>
                    
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleContact2')! }}>
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleContact3')! }}>
                    </p>
                </Col>
                <Col xs={0} sm={0} md={1} lg={2}></Col>

            </Row>

            <Row>
                <Col xs={0} sm={0} md={0} lg={2}></Col>
                <Col xs={12} sm={12} md={12} lg={8} >
                    <h5 dangerouslySetInnerHTML={{ __html: t('Pages.Legal.ConditionTitle')! }}></h5>
                </Col>
            </Row>

            <Row>
                <Col xs={0} sm={0} md={0} lg={2}></Col>
                <Col xs={12} sm={12} md={12} lg={8} >
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleCondition1')! }}></p>
                    <ul>
                        <li dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleCondition2')! }} />
                        <li dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleCondition3')! }} />
                        <li dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleCondition4')! }} />
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col xs={0} sm={0} md={0} lg={2}></Col>
                <Col xs={12} sm={12} md={12} lg={8} >
                </Col>
            </Row>
            <Row>
                <Col xs={0} sm={0} md={0} lg={2}></Col>
                <Col xs={12} sm={12} md={12} lg={8} >
                    <h5 dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleCondition5')! }}></h5>
                </Col>
            </Row>
            <Row>
                <Col xs={0} sm={0} md={0} lg={2}></Col>
                <Col xs={12} sm={12} md={12} lg={8} >
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleCondition6')! }}>
                    </p>
                </Col>
            </Row>

            <Row>
                <Col xs={0} sm={0} md={0} lg={2}></Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleCondition7')! }} >
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleCondition8')! }}></p>
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleCondition9')! }}></p>
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleCondition10')! }}></p>
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Legal.SubTitleCondition11')! }}></p>
                </Col>
            </Row>

            <Row>
                <Col xs={0} sm={0} md={0} lg={2}></Col>
                <Col xs={12} sm={12} md={12} lg={8} >
                    <p>{t('Pages.Legal.SubTitleCondition12')}</p>
                    <p>{t('Pages.Legal.SubTitleCondition13')}</p>
                    <p>{t('Pages.Legal.SubTitleCondition14')}</p>
                    <p>{t('Pages.Legal.SubTitleCondition15')}</p>
                    <p>{t('Pages.Legal.SubTitleCondition16')}</p>
                </Col>
            <p style={{ marginBottom: '25px' }}></p>
            </Row>
        </Section>

    </>
};

export default MentionsLegales;