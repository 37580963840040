import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Section from '../shared/Section';
import './valence.css';

import { ArrowUpRight } from 'react-feather';
import { ValenceMobilite, ValenceTransport, GpsBox, Fichier1, Fichier2, Fichier3, Fichier4 } from './../../assets/img/valence';


const Valence = (): React.JSX.Element => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <>
                <Section className="section-etude py-5">
                    <Row>
                        <Col xs={12} lg={6} className="text-center">
                            <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Valence.Title')! }}></h4>
                        </Col>
                        <Col xs={12} lg={6}>
                            <div className="p-3">
                                <p className='case-study-desc' dangerouslySetInnerHTML={{ __html: t('Pages.Valence.SubTitle')! }}></p>
                            </div>
                        </Col>
                    </Row>
                </Section>
                <div className='background-image valence'></div>
                <Section className='case-study'>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={10}>
                            <div className='center-box'>
                            <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Valence.GlobalTitle')! }}></h4>
                                <p>{t('Pages.Valence.GlobalDescription')}</p>
                                <ArrowUpRight className='icon' size={48}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className='text-center py-5'>
                        <p dangerouslySetInnerHTML={{ __html: t('Pages.Valence.Quote')! }}></p>
                        <p className='text-start fw-bold' dangerouslySetInnerHTML={{ __html: t('Pages.Valence.Author')! }}></p>
                    </Row>
                    <Row className='text-center py-3'>
                        <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Valence.Deploy')! }}></h4>
                    </Row>
                    <Row className='justify-content-center text-center'>
                        <Col xs={12} md={6} lg={3}>
                            <div className='block'>
                                <img src={Fichier1} alt={t('Pages.Valence.GpsBoxTitle')} style={{ width: '120px' }} />
                                <p dangerouslySetInnerHTML={{ __html: t('Pages.Valence.GpsBoxTitle')! }}></p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className='block'>
                                <img src={Fichier2} alt={t('Pages.Valence.FraudTitle')} style={{ width: '120px' }} />
                                <p dangerouslySetInnerHTML={{ __html: t('Pages.Valence.FraudTitle')! }}></p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className='block'>
                                <img src={Fichier3} alt={t('Pages.Valence.Mobilite')} style={{ width: '120px' }} />
                                <p dangerouslySetInnerHTML={{ __html: t('Pages.Valence.Mobilite')! }}></p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className='block'>
                            <img src={Fichier4} alt={t('Pages.Valence.Transport')} style={{ width: '120px' }} />
                                <p dangerouslySetInnerHTML={{ __html: t('Pages.Valence.Transport')! }}></p>
                            </div>
                        </Col>
                    </Row>
                </Section>
            </>);
};

export default Valence;