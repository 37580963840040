import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Section from '../shared/Section';
import './rouen.css';
import { Fichier1, Fichier2, Fichier3, Fichier4, ImgRouen } from './../../assets/img/rouen';
import { ArrowUpRight } from 'react-feather';


const Rouen = (): React.JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <Section className="section-etude py-5">
                <Row>
                    <Col xs={12} lg={6} className="text-center">
                        <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Rouen.Title')! }}></h4>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className="p-3">
                            <p className='case-study-desc'>{t('Pages.Rouen.SubTitle')} </p>
                        </div>
                    </Col>
                </Row>
            </Section>
            <div className='background-image'></div>
            <Section className='case-study'>
                <Row className='justify-content-center'>
                    <Col xs={12} md={10}>
                        <div className='center-box'>
                        <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Rouen.Title2')! }}></h4>
                            <p>{t('Pages.Rouen.Description')}</p>
                            <ArrowUpRight className='icon' size={48}/>
                        </div>
                    </Col>
                </Row>
                <Row className='text-center py-5'>
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Rouen.Quote')! }}></p>
                    <p className='text-start fw-bold' dangerouslySetInnerHTML={{ __html: t('Pages.Rouen.Author')! }}></p>
                </Row>
                <Row className='text-center py-3'>
                    <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Rouen.Title3')! }}></h4>
                </Row>
                <Row className='justify-content-center text-center'>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block'>
                            <img src={Fichier1} alt={t('Pages.Rouen.AltIconComputer')} style={{ width: '120px' }} />
                            <p>{t('Pages.Rouen.Computer')}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block'>
                            <img src={Fichier2} alt={t('Pages.Rouen.AltIconPeople')} style={{ width: '120px' }} />
                            <p>{t('Pages.Rouen.People')}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block'>
                            <img src={Fichier3} alt={t('Pages.Rouen.AltIconWatch')} style={{ width: '120px' }} />
                            <p>{t('Pages.Rouen.Watch')}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block'>
                            <img src={Fichier4} alt={t('Pages.Rouen.AltIconTransports')} style={{ width: '120px' }} />
                            <p>{t('Pages.Rouen.Transports')}</p>
                        </div>
                    </Col>
                </Row>
            </Section>
        </>
    );
};

export default Rouen;