import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ArrowLeft, ArrowLeftCircle, ArrowRight, ArrowRightCircle, Circle, MessageCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { CarrousselsData, CarrousselsDataRoot } from '../types/CarrousselsData';
import "./carrousels.css";
import { Modal } from 'react-bootstrap';

const Carroussels = () => {
    const { t } = useTranslation();
    const [CarrousselsData, setCarrousselsData] = useState<CarrousselsData[] | undefined>(undefined);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = React.useState(false);
    const CarrousselsUrl = "/api/expertises/expertises.json";
    const [itemsPerPage, setItemsPerPage] = useState(3);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedItem, setSelectedItem] = useState<CarrousselsData | undefined>(undefined);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(CarrousselsUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data: CarrousselsDataRoot = await response.json();
                setCarrousselsData(data.data);
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            } catch (err) { }
        };

        fetchData();

        const updateItemsPerPage = () => {
            if (window.innerWidth < 768) {
                setItemsPerPage(1);
            } else if (window.innerWidth < 992) {
                setItemsPerPage(2);
            } else {
                setItemsPerPage(3);
            }
        };

        updateItemsPerPage();
        window.addEventListener('resize', updateItemsPerPage);

        return () => window.removeEventListener('resize', updateItemsPerPage);
    }, []);

    const nextCarroussels = () => {
        if (CarrousselsData && currentIndex + itemsPerPage < (CarrousselsData.length)) {
            setCurrentIndex(currentIndex + itemsPerPage);
        }
    };

    const prevCarroussels = () => {
        if (currentIndex - itemsPerPage >= 0) {
            setCurrentIndex(currentIndex - itemsPerPage);
        }
    };

    const currentCarroussels = CarrousselsData ? CarrousselsData.slice(currentIndex, currentIndex + itemsPerPage) : [];
    const columns = currentCarroussels.map((ttm, index) => (
        <Col xs={12} sm={12} md={6} lg={4} key={index} className="carroussel" onClick={()=>{setSelectedItem(ttm);handleShow();}} >
            <div className="message-content">
                <p className="message-title" dangerouslySetInnerHTML={{ __html: `<strong>${t(ttm.title)}</strong>` }} />
                <div className="message-text">
                     <p className="text" dangerouslySetInnerHTML={{__html: t(ttm.message)}}></p>
                </div>
            </div>
        </Col>
    ));

    return (
        <div className="carroussels-container">
            <button onClick={prevCarroussels} disabled={currentIndex === 0}>
                <ArrowLeftCircle size={42} />
            </button>
            <Row>
                {!loading && columns}
            </Row>
            <button onClick={nextCarroussels} disabled={!CarrousselsData || currentIndex + itemsPerPage >= CarrousselsData.length}>
                <ArrowRightCircle size={42} />
            </button>
            <Modal className='expertise-modal' size='lg' show={show} onHide={handleClose} centered>
                <div className='content'>
                    <h5 dangerouslySetInnerHTML={{ __html: `<strong>${t(selectedItem != undefined ? selectedItem.title : "")}</strong>` }}></h5>
                    <p dangerouslySetInnerHTML={{ __html: t(selectedItem != undefined ? selectedItem.message : "")}}></p>
                </div>
            </Modal>
        </div>
    );
};

export default Carroussels;
