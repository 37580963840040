import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Section from '../shared/Section';
import './reunion.css';

import { ArrowUpRight } from 'react-feather';
import { ReunionLines, ReunionDirection, ReunionTime, ReunionFreq, ReunionAlterneo } from './../../assets/img/reunion';


const Reunion = (): React.JSX.Element => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
    <>
            <Section className="section-etude py-5">
                <Row>
                    <Col xs={12} lg={6} className="text-center">
                        <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Reunion.Title')! }}></h4>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className="p-3">
                            <p className='case-study-desc' dangerouslySetInnerHTML={{ __html: t('Pages.Reunion.SubTitle')! }}></p>
                        </div>
                    </Col>
                </Row>
            </Section>
            <div className='background-image reunion'></div>
            <Section className='case-study'>
                <Row className='justify-content-center'>
                    <Col xs={12} md={10}>
                        <div className='center-box'>
                        <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Reunion.GlobalTitle')! }}></h4>
                            <p>{t('Pages.Reunion.GlobalDescription')}</p>
                            <ArrowUpRight className='icon' size={48}/>
                        </div>
                    </Col>
                </Row>
                <Row className='text-center py-5'>
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Reunion.Quote')! }}></p>
                    <p className='text-start fw-bold' dangerouslySetInnerHTML={{ __html: t('Pages.Reunion.Author')! }}></p>
                </Row>
                <Row className='text-center py-3'>
                    <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Reunion.Deploy')! }}></h4>
                </Row>
                <Row className='justify-content-center text-center'>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block'>
                            <img src={ReunionLines} alt={t('Pages.Reunion.IconTextLines')} style={{ width: '120px' }} />
                            <p dangerouslySetInnerHTML={{ __html: t('Pages.Reunion.IconTextLines')! }}></p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block'>
                            <img src={ReunionDirection} width='300px' alt={t('Pages.Reunion.IconTextDirection')} style={{ width: '120px' }} />
                            <p dangerouslySetInnerHTML={{ __html: t('Pages.Reunion.IconTextDirection')! }}></p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block' >
                            <img src={ReunionTime} alt={t('Pages.Reunion.IconTextTime')} style={{ width: '120px' }} />
                            <p dangerouslySetInnerHTML={{ __html: t('Pages.Reunion.IconTextTime')! }}></p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block'>
                            <img src={ReunionFreq} width='250px' alt={t('Pages.Reunion.IconTextFreq')} style={{ width: '120px' }} />
                            <p dangerouslySetInnerHTML={{ __html: t('Pages.Reunion.IconTextFreq')! }}></p>
                        </div>
                    </Col>
                </Row>
            </Section>
        </>);
};

export default Reunion;