import React from "react";
import { useTranslation } from "react-i18next";
import { ExternalLink } from 'react-feather';

import { LinkedinData } from "./types/LinkedinData";
import './linkedin.css';
// -- temporaire
import { Author_1, Img_1, Author_2, Img_2, Author_3, Img_3, FlowlyDefault } from './../../assets/img/linkedin';

type LinkedinPostProps = {
    post: LinkedinData
}

// -- temporaire
interface ImageMap {
    [key: string]: string;
}
const images: ImageMap = {
    Author_1: Author_1,
    Img_1: Img_1,
    Author_2: Author_2,
    Img_2: Img_2,
    Author_3: Author_3,
    Img_3: Img_3
};


const LinkedinPost = ({post} : LinkedinPostProps): React.JSX.Element => {

    const [lang, setLang] = React.useState("en");
    const [message, setMessage] = React.useState("");
    const { t } = useTranslation();

    React.useEffect(() => {
        const preferredLang = localStorage.getItem("lang") ?? navigator.language?.split("-")[0] ?? "en";
        setLang(preferredLang);

        const getLocalizedMessage = (
            lang: string,
            post: LinkedinData,
            defaultMessage: string
        ): string => {
            if (lang === "fr" && post?.frMessage) return post.frMessage;
            if (lang === "en" && post?.enMessage) return post.enMessage;
            if (lang === "es" && post?.esMessage) return post.esMessage;
            return defaultMessage || "";
        };

        setMessage(getLocalizedMessage(preferredLang, post, post.postMessage));
    }, []);

    return (
        <div className="linkedin-post small">
            <div className="header">
                <div className="avatar">
                    {/*<img src={post.userImgSrc !== undefined ? images[post.userImgSrc] : ""}/>*/}
                    <img src={Author_1} />
                </div>
                <div className="user-infos small">
                    <div className="user-name">{post.userName}</div>
                    {/* <div className="user-role">{post.userRole}</div> */}
                    <div className="post-date">{post.postDate}</div>
                </div>
            </div>
            <div className="content">
                <div className="post-message">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: message,
                        }}
                    />

                </div>
                <div className="post-img">
                    <a href={post.postLink} target="_blank">
                        <img src={post.postImgSrc !== "null" ? "../../assets/img/linkedin/" + post.postImgSrc?.replace(/\s+/g, '') : FlowlyDefault} />
                    </a>
                </div>
                <div className="post-link">
                    <a href={post.postLink} target="_blank"><ExternalLink/></a>
                </div>
            </div>
        </div>
    );
}

export default LinkedinPost;