var _paq = window._paq = window._paq || [];
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
_paq.push(['enableHeartBeatTimer']);
_paq.push(['trackVisibleContentImpressions']);
(function() {
	var u = "https://matomo.flowly.re/analytics/matomo/";
	_paq.push(['setTrackerUrl', u + 'matomo.php']);
	_paq.push(['MediaAnalytics::scanForMedia', document]);
	_paq.push(['setSiteId', '3']);
	var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
	g.type = 'text/javascript'; g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
})();


