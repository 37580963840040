import React from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-feather';

import { MediaDataUnit } from './types/MediaData';
import { MediaDefault } from './../../assets/img/media';

import './media.css';

type MediaProps = {
    media: MediaDataUnit,
    
};

const Medias = ({media} : MediaProps): React.JSX.Element => {

    const {t} = useTranslation();

    return (
        <div className='media-wrapper'>
            <a href={media.link} target='_blank'>
                <div className='media-card'>
                    <div className='media-card-img-container'>
                        <img 
                            onError={({currentTarget})=>{
                                currentTarget.onerror = null;
                                currentTarget.src = MediaDefault;
                            }}  
                        src={media.imgSrc === undefined ? MediaDefault : media.imgSrc }  alt={media.imgAlt} />
                        
                    </div>
                    <div className='overlay'></div>
                    <div className='media-card-content-container'>
                        <h6 className='title'>{media.title}</h6>
                        <p className='date'>{media.date}</p>
                        <p className='desc'>{media.desc}</p>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default Medias;