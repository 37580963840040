import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { router } from './AppRoutes';
import './i18n';

import 'bootstrap/dist/css/bootstrap.min.css';
import './charte.css';
import './slider.css';
import './navbar.css';
import './assets/css/society.css';
import './politic.css';
import './cookie.css';
import './matomo_script.js';


    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode> 
        <RouterProvider router={router}></RouterProvider>
    </React.StrictMode>
);