import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Section from '../shared/Section';
import './douai.css';
import { ArrowUpRight } from 'react-feather';
import { direction, loupe, performance, carto } from './../../assets/img/douai';


const Douai = (): React.JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <Section className="section-etude py-5">
                <Row>
                    <Col xs={12} lg={6} className="text-center">
                        <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Douai.Title')! }}></h4>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className="p-3">
                            <p className='case-study-desc'>{t('Pages.Douai.SubTitle')} </p>
                        </div>
                    </Col>
                </Row>
            </Section>
            <div className='background-image douai'></div>
            <Section className='case-study'>
                <Row className='justify-content-center'>
                    <Col xs={12} md={10}>
                        <div className='center-box'>
                            <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Douai.Title2')! }}></h4>
                            <p>{t('Pages.Douai.Description1')}</p>
                            <p>{t('Pages.Douai.Description2')}</p>
                            <p>{t('Pages.Douai.Description3')}</p>
                            <ArrowUpRight className='icon' size={48} />
                        </div>
                    </Col>
                </Row>
                <Row className='text-center py-3'>
                    <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Douai.Title3')! }}></h4>

                    <Col xs={12} md={12} lg={12}>
                        <p className='center-text' dangerouslySetInnerHTML={{ __html: t('Pages.Douai.SousTexte1')! }}></p>
                        <p className='center-text' dangerouslySetInnerHTML={{ __html: t('Pages.Douai.SousTexte2')! }}></p>
                        <p className='center-text ' dangerouslySetInnerHTML={{ __html: t('Pages.Douai.SousTexte3')! }}></p>
                        <p className='center-text ' dangerouslySetInnerHTML={{ __html: t('Pages.Douai.SousTexte4')! }}></p>
                        <p className='center-text ' dangerouslySetInnerHTML={{ __html: t('Pages.Douai.SousTexte5')! }}></p>
                        <p className='center-text ' dangerouslySetInnerHTML={{ __html: t('Pages.Douai.SousTexte6')! }}></p>
                    </Col>
                </Row>
                <Row className='justify-content-center text-center'>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block'>
                            <img src={loupe} alt={t('Pages.Douai.Parcours')} style={{ width: '120px' }} />
                            <p>{t('Pages.Douai.Parcours')}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block'>
                            <img src={direction} alt={t('Pages.Douai.DirectionIcon')} style={{ width: '120px' }} />
                            <p>{t('Pages.Douai.DirectionIcon')}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block'>
                            <img src={performance} alt={t('Pages.Douai.AltIconClock')} style={{ width: '120px' }} />
                            <p>{t('Pages.Douai.PerformanceIcon')}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <div className='block'>
                            <img src={carto} alt={t('Pages.Douai.AltIconArrow')} style={{ width: '120px' }} />
                            <p>{t('Pages.Douai.SuiviCartoIcon')}</p>
                        </div>
                    </Col>
                </Row>
            </Section>
        </>
    );
};

export default Douai;