import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

import Section from '../shared/Section';
import TeamCard from './TeamCard';
import { TeamData, TeamDataRoot } from './types/TeamData';
import { DefaultMen, DefaultWomen, DefaultYoungMen, DefaultYoungWomen } from '../../assets/img/team';
import './teamcard.css';
import ImageLoader from '../shared/loader/ImageLoader';

const teamUrl = "/api/team/team.json";
const Team = (): React.JSX.Element => {

    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [teamData, setTeamData] = useState<TeamData[]>();
    const loaders = Array(10).fill(0);
    var langue = "fr";
    React.useEffect(() => {
        langue = localStorage.getItem("lang") === undefined ? navigator.language : localStorage.getItem("lang")!;
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(teamUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data: TeamDataRoot = await response.json();
                if (langue = "fr") {
                    setTeamData(data.data_fr);

                }
                if (langue = "en") {
                    setTeamData(data.data_en);

                }
                if (langue = "fr") {
                    setTeamData(data.data_fr);

                }
                setTimeout(()=>{
                    setLoading(false);
                }, 1000);
            } catch (err) {}
        };

        fetchData();
    }, []);

    return <>
        <Section className="section-team-card">
            <Row >
                <Col xs={12} className="text-center">
                    <h2 dangerouslySetInnerHTML={
                        { __html: t('Pages.Team.Title') === undefined ? "" : t('Pages.Team.Title') }
                    } />
                </Col>
                <Col xs={12} className="text-center">
                    <p dangerouslySetInnerHTML={
                        { __html: t('Pages.Team.Subtitle') === undefined ? "" : t('Pages.Team.Subtitle') }
                    } />
                </Col>
            </Row>
        </Section>
        <Section className="section-team-card-bg">
            {
                <Row>
                    {
                        loading && loaders.map((el, index)=>
                            <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4} className="text-center mt-4" style={{height: "300px"}}>
                                <ImageLoader />
                            </Col>
                        )
                    }
                    {
                        !loading && teamData?.map((group)=><>
                            {
                                group.data.map((team, index) =>
                                    <Col xs={12} sm={12} md={6} lg={4} xl={4} className="text-center mt-4">
                                        <TeamCard job={team.job} lastname={team.lastname} firstname={team.firstname} description={team.description} link={team.link} category={team.category} img={team.img} />
                                    </Col>
                                )   
                            }
                        </>)
                    }
                </Row>
            }
        </Section>
    </>
};

export default Team;