import React, { useState, ChangeEvent, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { Phone, Mail, MapPin } from "react-feather";
import { Linkedin } from 'react-feather';

import Section from "../shared/Section";

import './contact.css';
import { useSearchParams } from "react-router-dom";

type ResultProps = {
    code: number,
    overlay: boolean,
    loading: boolean
};

const Result = ({ code, overlay, loading }: ResultProps): React.JSX.Element => {
    const { t, i18n } = useTranslation();
    return <>
        {overlay &&
            <div className="contact-overlay">
                {
                    loading ? <Spinner className="flowly-orange"></Spinner> :
                        code > 199 && code < 300 ?
                            <h6>{t('Pages.Contact.Success')}</h6> :
                            <h6>{t('Pages.Contact.Error')}</h6>
                }
            </div>
        }
    </>
}

const Contact = (): React.JSX.Element => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const path = location.pathname;
    const [searchParams, setSearchParams] = useSearchParams();
    const [validated, setValidated] = useState(false);
    const [overlay, setOverlay] = useState(false);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(200);
    const [formData, setFormData] = useState({
        name: '',
        firstname: '',
        phone: '',
        email: '',
        subject: '',
        message: ''
    });

    React.useEffect(()=>{
        if (searchParams == undefined) return;
        if (searchParams.get("link") == undefined) return;
        setFormData({
            ...formData,
            subject: t('Pages.Contact.Demo')
        });
    }, [path, i18n.language, t]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleTAChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        setValidated(true);
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setOverlay(true);
            setLoading(true);
            try {
                const response = await fetch('/SubmitForm', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                const result = await response.json();
                setResult(result.code);
                if (result.code > 199 && result.code < 300) {
                    setValidated(false);
                    setLoading(false);
                    setTimeout(() => {
                        setOverlay(false);
                    }, 3000)
                    handleReset();
                } else {
                    setValidated(true);
                    setLoading(false);
                    setTimeout(() => {
                        setOverlay(false);
                    }, 3000)
                }
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const handleReset = () => {
        setFormData({
            name: '',
            firstname: '',
            phone: '',
            email: '',
            subject: '',
            message: ''
        });
    };

    return (
        <>
            <Section className="contact-section">
                <Row className="contact-section py-4">
                    <Col lg={2}>
                        <div className="smedia-sidebar">
                            <div className="smedia-content">
                                <a href="https://www.linkedin.com/company/flowly1/" target="_blank" className="smedia-sidebar"><Linkedin size="18" /></a>
                                <a href="https://www.linkedin.com/company/flowly1/" target="_blank" className="smedia-divider"></a>
                                <a href="https://www.linkedin.com/company/flowly1/" target="_blank" className="smedia-text">{t("NavBar.FollowUs")}</a>
                            </div>
                            </div>
                        </Col>
                    <Col lg={8} xs={12} style={{zIndex: 98}} className="fading-white-bg">
                        <h2 dangerouslySetInnerHTML={{ __html: t('Pages.Contact.Title')! }}></h2>
                        <p>{t('Pages.Contact.SubTitle')}</p>
                    </Col>
                    <Col xs={0} sm={0} md={1} lg={2}></Col>

                </Row>
            </Section>
            <Section className="pb-4 contact-section">

                <Row className="text-center contact-section-container pb-4">
                    <Col xs={12} lg={4} className="contact-elements">
                        <Row className="wrapper">
                             <Col md={12}>
                                <div className="element">
                                    <Mail className="icon" size={16}/><p>contact@flowly.re</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="element">
                                    <MapPin className="icon" size={16}/><p>14 Rue Jules Thirel, Saint-Paul, 97460</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={8} className="position-relative">
                        <Result code={result} loading={loading} overlay={overlay} />
                        <Form id="flowly-contact" noValidate validated={validated} onSubmit={handleSubmit} className="p-5 p-sm-0">
                            <Row className="contact-form-elements">
                                <Form.Group className="form-contact mt-1" as={Col} xs={12} md={6} controlId="validationCustom01">
                                    <Form.Label className="text-left fw-bold w-100" >{t('Pages.Contact.Name')}</Form.Label>
                                    <Form.Control onChange={handleChange} value={formData.name} className="contact-form-input" name="name" required type="text" autoComplete="off" />
                                </Form.Group>
                                <Form.Group className="form-contact mt-1" as={Col} xs={12} md={6} controlId="validationCustom02">
                                    <Form.Label className="text-left fw-bold w-100" >{t('Pages.Contact.Mail2')}</Form.Label>
                                    <Form.Control onChange={handleChange} value={formData.email} className="contact-form-input" name="email" required type="email" autoComplete="off" />
                                </Form.Group>
                            </Row>
                            <Row className="contact-form-elements">
                                <Form.Group className="form-contact mt-1" as={Col} xs={12} md={6}>
                                    <Form.Label className="text-left fw-bold w-100" >{t('Pages.Contact.Firstname')}</Form.Label>
                                    <Form.Control onChange={handleChange} value={formData.firstname} className="contact-form-input" name="firstname" type="hidden" autoComplete="off" />
                                </Form.Group>
                                <Form.Group className="form-contact mt-1" as={Col} xs={12} md={6}>
                                    <Form.Label className="text-left fw-bold w-100" >{t('Pages.Contact.Phone')}</Form.Label>
                                    <Form.Control onChange={handleChange} value={formData.phone} className="contact-form-input" name="phone" type="hidden" autoComplete="off" />
                                </Form.Group>
                            </Row>
                            <Row className="contact-form-elements">
                                <Form.Group className="form-contact mt-1" as={Col} xs={12} controlId="validationCustom03">
                                    <Form.Label className="text-left fw-bold w-100" >{t('Pages.Contact.Demand')}</Form.Label>
                                    <Form.Control onChange={handleChange} value={formData.subject} className="contact-form-input" name="subject" required type="text" autoComplete="off" />
                                </Form.Group>
                            </Row>
                            <Row className="contact-form-elements">
                                <Form.Group className="form-contact mt-1" as={Col} xs={12} controlId="validationCustom04">
                                    <Form.Label className="text-left fw-bold w-100" >{t('Pages.Contact.Message')}</Form.Label>
                                    <Form.Control onChange={handleTAChange} value={formData.message} as="textarea" className="contact-form-input" name="message" required autoComplete="off" />
                                </Form.Group>
                            </Row>
                            <Row className="justify-content-end p-2">
                                <Button className="flowly-btn orange" type="submit" disabled={loading}>
                                    {t('Pages.Contact.Send')}
                                </Button>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Section>
        </>
    );
}

export default Contact;
