import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Section from '../shared/Section';
import { DataBase, Device, Chain, CompleteData, Folder, FileLock, ClientInfo, TransfertData } from './../../assets/img/privacy';
import { CarFlux, Funnel, Map, WifiBox, Phone, Dashboard, Bus, Bus2, Map2, Clock, Rainbow, FlowlySensors, Fraude, FlowlyFraude, Fraude_miniature, FlowlySensors_miniature, Dashboard_miniature, FlowlyApi, FlowlyApiIcon } from '../../assets/img/the-solution';
import { AlignLeft, ArrowUpRight } from 'react-feather';

import './the-solution.css';

import Lotties from '../shared/Lotties';
import SolutionAnimation from '../../../src/assets/lottie/solution.json';
import {frAnimation, enAnimation, esAnimation} from '../../../src/assets/lottie/index';

const TheSolution = (): React.JSX.Element => {
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = React.useState<string | null>();
    const floatingNav = React.useRef(null);
    const [lang, setLang] = React.useState<string | null>(null);

    React.useEffect(() => {

        const preferredLang = localStorage.getItem("lang") ?? navigator.language?.split("-")[0] ?? "en";
        setLang(preferredLang);

        window.addEventListener('scroll', (e) => {
            const navDropdown = document.querySelector('.navbar-language .dropdown-menu');
            const topRef = document.querySelector('.topref');
            const backMobile = document.querySelector('.back_mobile');
            if (topRef != undefined && floatingNav.current != undefined) {
                const mob = backMobile as HTMLElement;
                const rect = topRef as HTMLElement;
                const nav = floatingNav.current as HTMLElement;
                if (navDropdown?.classList.contains('show')) {

                    if (rect.getBoundingClientRect().top < window.screenY + 250) {
                        nav.style.position = 'fixed';
                        nav.style.top = '250px';
                        mob.style.position = 'fixed';
                        mob.style.top = '250px';
                    } else {
                        nav.style.position = 'absolute';
                        nav.style.top = '20px';
                        mob.style.position = 'absolute';
                        mob.style.top = '20px';
                    }
                } else {

                    if (rect.getBoundingClientRect().top < window.screenY + 100) {
                        nav.style.position = 'fixed';
                        nav.style.top = '100px';
                        mob.style.position = 'fixed';
                        mob.style.top = '100px';
                    } else {
                        nav.style.position = 'absolute';
                        nav.style.top = '20px';
                        mob.style.position = 'absolute';
                        mob.style.top = '20px';
                    }
                }
            }
        });
    }, []);

    React.useEffect(()=>{
        if (selectedItem == undefined)
            return;
        const topRef = document.querySelector('.blank') as HTMLElement;
        if (topRef != undefined) {
            const y = topRef.offsetTop;
            window.scrollTo({
                top: y,
                behavior: 'smooth'
            });
        }
    }, [selectedItem]);


    React.useEffect(() => {

        const preferredLang = localStorage.getItem("lang") ?? navigator.language?.split("-")[0] ?? "en";
        setLang(preferredLang);

    }, [t]);

    return <>
        <Section className="hero-section py-5">
            <div className="text-start title">
                <h2 dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Title')! }} />
                <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.SubTitle')}}></p>
                <img src={CarFlux} className='hero-img-mobile'/>
            </div>
        </Section>
        <img src={CarFlux} className='hero-img'/>
        <div className='blank'></div>
        <Section className='position-relative py-5 topref' dataTrackContent={true} dataContentName='solution-header'>
            {
                selectedItem != null &&      
                <>
                <div className="floating-nav fading-white-bg" ref={floatingNav} >
                    <div className='othersData'>
                        {
                            selectedItem == "data" ? <>
                            <Row>
                                <Col xs={2}>
                                    <div className="reduce-product-img" onClick={() => { setSelectedItem("API") }}>
                                        <img src={FlowlyApiIcon} />
                                    </div>
                                </Col>
                                <Col xs={10}>
                                    <div onClick={() => { setSelectedItem("API") }}>
                                        <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.TitleAPI')}</p>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={2}>
                                    <div className="reduce-product-img" onClick={() => { setSelectedItem("sensors") }}>
                                         <img src={FlowlySensors} />
                                    </div>
                                </Col>
                                <Col xs={10}>
                                    <div onClick={() => { setSelectedItem("sensors") }}>
                                        <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.Title3')}</p>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={2}>
                                    <div className="reduce-product-img" onClick={() => { setSelectedItem("fraud")}}>
                                        <img src={Fraude} />
                                    </div>
                                </Col>
                                <Col xs={10}>
                                    <div onClick={() => { setSelectedItem("fraud")}}>
                                        <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.TitleFraud')}</p>
                                    </div>
                                </Col>
                            </Row>
                            </> : <>
                            </>
                        }
                    </div>
                    <div className='othersFraud'>
                        {
                            selectedItem == "fraud" ? <>
                                    <Row>
                                        <Col xs={2}>
                                            <div className="reduce-product-img" onClick={() => { setSelectedItem("API")}}>
                                                <img src={FlowlyApiIcon} />
                                            </div>
                                        </Col>
                                        <Col xs={10}>
                                            <div onClick={() => { setSelectedItem("API") }}>
                                                <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.TitleAPI')}</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={2}>
                                            <div className="reduce-product-img" onClick={() => { setSelectedItem("sensors") }}>
                                                <img src={FlowlySensors} />
                                            </div>
                                        </Col>
                                        <Col xs={10}>
                                            <div onClick={() => { setSelectedItem("sensors") }}>
                                                <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.Title3')}</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={2}>
                                            <div className="reduce-product-img" onClick={() => { setSelectedItem("data") }}>
                                                <img src={Dashboard} />
                                            </div>
                                        </Col>
                                        <Col xs={10}>
                                            <div onClick={() => { setSelectedItem("data") }}>
                                                <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.Title2')}</p>
                                            </div>
                                        </Col>
                                    </Row>                                    
                            </> : <>
                            </>
                        }
                    </div>
                    <div className='othersSensors'>
                        {
                            selectedItem == "sensors" ? <>
                                    <Row>
                                        <Col xs={2}>
                                            <div className="reduce-product-img" onClick={() => { setSelectedItem("API") }}>
                                                <img src={FlowlyApiIcon} />
                                            </div>
                                        </Col>
                                        <Col xs={10}>
                                            <div onClick={() => { setSelectedItem("API") }}>
                                                <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.TitleAPI')}</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={2}>
                                            <div className="reduce-product-img" onClick={() => { setSelectedItem("fraud") }}>
                                                <img src={Fraude} />
                                            </div>
                                        </Col>
                                        <Col xs={10}>
                                            <div onClick={() => { setSelectedItem("fraud") }}>
                                                <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.TitleFraud')}</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={2}>
                                            <div className="reduce-product-img" onClick={() => { setSelectedItem("data") }}>
                                                <img src={Dashboard} />
                                            </div>
                                        </Col>
                                        <Col xs={10}>
                                            <div onClick={() => { setSelectedItem("data") }}>
                                                <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.Title2')}</p>
                                            </div>
                                        </Col>
                                    </Row>   
                            </> : <>
                            </>
                        }
                    </div>
                    <div className='othersAPI'>
                        {
                            selectedItem == "API" ? <>
                                    <Row>
                                        <Col xs={2}>
                                            <div className="reduce-product-img" onClick={() => { setSelectedItem("fraud") }}>
                                                <img src={Fraude} />
                                            </div>
                                        </Col>
                                        <Col xs={10}>
                                            <div onClick={() => { setSelectedItem("fraud") }}>
                                                <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.TitleFraud')}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={2}>
                                            <div className="reduce-product-img" onClick={() => { setSelectedItem("sensors") }}>
                                                <img src={FlowlySensors} />
                                            </div>
                                        </Col>
                                        <Col xs={10}>
                                            <div onClick={() => { setSelectedItem("sensors") }}>
                                                <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.Title3')}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={2}>
                                            <div className="reduce-product-img" onClick={() => { setSelectedItem("data") }}>
                                                <img src={Dashboard} />
                                            </div>
                                        </Col>
                                        <Col xs={10}>
                                            <div onClick={() => { setSelectedItem("data") }}>
                                                <p>{t('Pages.Solution.Discover')} {t('Pages.Solution.Title2')}</p>
                                            </div>
                                        </Col>
                                    </Row>   
                            </> : <>
                            </>
                        }
                    </div>
                    <div className='back' onClick={()=>{
                        setSelectedItem(null);
                    }}>
                            <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Title')! }} />
                    </div>
                </div>
                <div className='back_mobile fading-white-bg' onClick={()=>{
                    setSelectedItem(null);
                }}>
                        <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Title')! }} />
                </div>
                </>
            }
            {
            selectedItem == null && <Row className='justify-content-center'>
                <Col xs={12} md={6}>
                    <div className='product-card'>
                        <img src={Dashboard} />
                        <div className='description'>
                            <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Title2')! }} />
                            <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.SubTitle2')}}></p>
                            <div className="btn-content pt-4">
                                <a onClick={()=>{
                                    setSelectedItem("data");
                                }} className="mt-2 flowly-btn red">{t("Pages.Home.ButtonMore")}<ArrowUpRight size={16} /></a>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div className='product-card'>
                        <img src={FlowlySensors} />
                        <div className='description'>
                            <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Title3')! }} />
                            <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.SubTitle3')! }} />
                            <div className="btn-content pt-4">
                                <a onClick={()=>{
                                    setSelectedItem("sensors");
                                }} className="mt-2 flowly-btn red">{t("Pages.Home.ButtonMore")}<ArrowUpRight size={16} /></a>
                            </div>
                        </div>
                    </div>  
                </Col>
                </Row>
            }
            {
                selectedItem == null && <Row className='justify-content-center'>
                    <Col xs={12} md={6}>
                        <div className='product-card'>
                            <img src={Fraude} width={"70 px"} />
                            <div className='description'>
                                <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Solution.TitleFraud')! }} />
                                <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.SubTitleFraud') }}></p>
                                <div className="btn-content pt-4">
                                    <a onClick={() => {
                                        setSelectedItem("fraud");
                                    }} className="mt-2 flowly-btn red">{t("Pages.Home.ButtonMore")}<ArrowUpRight size={16} /></a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className='product-card'>
                            <img src={FlowlyApiIcon} width={"140 px"} />
                            <div className='description'>
                                <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Solution.TitleAPI')! }} />
                                <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.SubTitleAPI')! }} />
                                <div className="btn-content pt-4">
                                    <a onClick={() => {
                                        setSelectedItem("API");
                                    }} className="mt-2 flowly-btn red">{t("Pages.Home.ButtonMore")}<ArrowUpRight size={16} /></a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            }
        {
            (selectedItem != null && selectedItem == "data") && <>
            <div data-track-content data-content-name='solution-data'>
                <Row className='justify-content-center pt-4'>
                    <Col xs={12} md={6} className='text-center'>
                            <h4 className="mt-5 resize-flowting-nav" dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Title2')! }} />
                            <p style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.SubTitle2')! }} />
                    </Col>
                </Row>
                <Row className='justify-content-center p-0'>
                    <Col xs={12} className='text-center p-0' style={{height: "fit-content"}}>
                        <Lotties lang={lang} />
                    </Col>
                </Row>
                    <Row className='justify-content-center py-4' style={{position:'relative',top: '-150px'}} >
                        <Col xs={12} md={8} className='text-center'>
                            <p style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.FlowlyDataText1')! }}></p>
                            <p style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.FlowlyDataText2')! }}></p>
                            <p style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.FlowlyDataText3')!}}></p>
                            <div className="btn-content pt-4">
                                <Link to="/expertises" className="mt-2 flowly-btn red">
                                    {t("Pages.Home.Expertise")}<ArrowUpRight size={16} />
                                </Link>
                            </div>
                        </Col>
                    </Row>
            </div>
            </>
            }
            {
                (selectedItem != null && selectedItem == "fraud") && <>
                <div data-track-content data-content-name='solution-fraud'>
                    <Row className='justify-content-center pt-4'>
                        <Col xs={12} md={6} className='text-center'>
                            <h4 className="mt-5 resize-flowting-nav" dangerouslySetInnerHTML={{ __html: t('Pages.Solution.TitleFraud')! }} />
                            <p style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.SubTitleFraud')! }} />
                        </Col>
                    </Row>
                    <Row className='justify-content-center pt-4'>
                        <img src={FlowlyFraude} style={{ width: '700px' }} />
                    </Row>
                    <Row className='justify-content-center py-4'>
                        <Col xs={12} md={8} className='text-center'>
                            <p style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.FlowlyFraudText1')! }}></p>
                            <p style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.FlowlyFraudText2')! }}></p>
                            <p style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.FlowlyFraudText3')! }}></p>
                            <div className="btn-content pt-4">
                                <Link to="/expertises" className="mt-2 flowly-btn red">
                                    {t("Pages.Home.Expertise")}<ArrowUpRight size={16} />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                </>
            }
            {
                (selectedItem != null && selectedItem == "API") && <>
                <div data-track-content data-content-name='solution-api'>
                    <Row className='justify-content-center pt-4'>
                        <Col xs={12} md={6} className='text-center'>
                            <h4 className="mt-5 resize-flowting-nav" dangerouslySetInnerHTML={{ __html: t('Pages.Solution.TitleAPI')! }} />
                            <p style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.SubTitleAPI')! }} />
                        </Col>
                    </Row>
                    <div>
                        <Row className='justify-content-center pt-4'>
                            <div className='logo-middle'>
                            <Col xs={12} md={6} className='logo-middle'>
                                <div className='logo-middle'>
                                <img src={FlowlyApi} style={{ width: '90%' }} />
                                </div>
                            </Col>
                            </div>
                        </Row>
                    </div>
                    <Row className='justify-content-center py-4'>
                        <Col xs={12} md={8} className='text-center'>
                            <p style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.FlowlyAPIText1')! }}></p>
                            <p style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.FlowlyAPIText2')! }}></p>
                            <div className="btn-content pt-4">
                                <Link to="/expertises" className="mt-2 flowly-btn red">
                                    {t("Pages.Home.Expertise")}<ArrowUpRight size={16} />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                </>
            }
        {
                (selectedItem != null && selectedItem == "sensors") && <> 
            <div data-track-content data-content-name='solution-sensors'>
                <Row className='justify-content-center py-4'>
                    <Col xs={12} md={8} className='text-center'>
                        <h4 className="mt-5 resize-flowting-nav" dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Title3')! }} />
                        <p style={{fontSize: "20px"}} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.SubTitle3')! }} />
                        <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.FilteredData')! }} className='text-center' style={{fontSize: "20px"}}></p>
                    </Col>
                </Row>
                <Row className='justify-content-center py-4 position-relative'>
                    <Col xs={12} md={8}>
                    </Col>
                </Row>
                <Row className='justify-content-center py-4'>
                    <Col xs={12} md={6}>
                        <div className='block text-center'>
                            <div className='image'>
                                <img src={WifiBox} />
                            </div>
                            <div>
                            <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.LogoBus')! }}></p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className='block text-center'>
                            <div className='image'>
                                <img src={Map} />
                            </div>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.LogoMap')! }}></p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='justify-content-center py-4'>
                    <Col xs={12} sm={6} lg={4}>
                        <div className='block text-center'>
                            <div className='image'>
                                <img src={Funnel}/>
                            </div>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Donnee')! }}></p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <div className='block text-center'>
                            <div className='image'>
                                <img src={Phone}/>
                            </div>
                            <div>
                            <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Reseau')! }}></p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <div className='block text-center'>
                            <div className='image'>
                                <img src={Dashboard}/>
                            </div>
                            <div>
                            <p dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Chiffre')! }}></p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='justify-content-center py-4 position-relative'>
                    <Col xs={12} md={8} className='text-center'>
                        <p style={{fontSize: "20px"}} dangerouslySetInnerHTML={{ __html: t('Pages.Solution.BetterUnderstandingDesc')! }} />
                        <img className='rainbow-2' src={Rainbow}/>
                    </Col>
                </Row>
                <Row className='justify-content-center py-4'>
                    <Col xs={12} sm={6} lg={4}>
                        <div className='block text-center'>
                            <div className='image'>
                                <img src={Bus}/>
                            </div>
                            <p className='fw-bold' dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Bus')! }}></p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <div className='block text-center'>
                            <div className='image'>
                                <img src={Map}/>
                            </div>
                            <p className='fw-bold' dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Location')! }}></p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <div className='block text-center'>
                            <div className='image'>
                                <img src={Bus2}/>
                            </div>
                            <p className='fw-bold' dangerouslySetInnerHTML={{ __html: t('Pages.Solution.BusPeople')! }}></p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <div className='block text-center'>
                            <div className='image'>
                                <img src={Map2}/>
                            </div>
                            <p className='fw-bold' dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Location2')! }}></p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <div className='block text-center'>
                            <div className='image'>
                                <img src={Clock}/>
                            </div>
                            <p className='fw-bold' dangerouslySetInnerHTML={{ __html: t('Pages.Solution.Watch')! }}></p>
                        </div>
                    </Col>
                </Row>
                <Row className='justify-content-center py-4'>
                    <Col xs={12} md={8} className='text-center'>
                        <h4 dangerouslySetInnerHTML={{ __html: t('Pages.Privacy.Title')! }} />
                        <p style={{fontSize: "20px"}} dangerouslySetInnerHTML={{ __html: t('Pages.Privacy.SubTitle')! }} />
                    </Col>
                </Row>
                <Row className='py-4'>
                    <Col md={12}  lg={6}>
                        <Row className='align-items-center privacy-card'>
                            <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                                <img alt={t('Pages.Privacy.NoCollect.AltImg')} src={Folder} />
                            </Col>
                            <Col md={9}  className='text-center text-md-start'>
                                <p>{t('Pages.Privacy.NoCollect.Description')}</p>
                            </Col>
                        </Row>
                        <Row className='align-items-center privacy-card'>
                            <Col xs={12}  md={3} className="image-elements pt-3 pt-md-0">
                                <img alt={t('Pages.Privacy.ClientInfo.AltImg')} src={ClientInfo} />
                            </Col>
                            <Col md={9} className='text-center text-md-start'>
                                <p>{t('Pages.Privacy.ClientInfo.Description')}</p>
                            </Col>
                        </Row>
                        <Row className='align-items-center privacy-card'>
                            <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                                <img alt={t('Pages.Privacy.Device.AltImg')} src={Device} />
                            </Col>
                            <Col md={9} className='text-center text-md-start'>
                                <p>{t('Pages.Privacy.Device.Description')}</p>
                            </Col>
                        </Row>
                        <Row className='align-items-center privacy-card'>
                            <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                                <img alt={t('Pages.Privacy.TransfertData.AltImg')} src={TransfertData} />
                            </Col>
                            <Col md={9} className='text-center text-md-start'>
                                <p>{t('Pages.Privacy.TransfertData.Description')}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} lg={6}>
                        <Row className='align-items-center privacy-card'>
                            <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                                <img alt={t('Pages.Privacy.DataBase.AltImg')} src={DataBase} />
                            </Col>
                            <Col md={9} className='text-center text-md-start'>
                                <p>{t('Pages.Privacy.DataBase.Description')}</p>
                            </Col>
                        </Row>
                        <Row className='align-items-center privacy-card'>
                            <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                                <img alt={t('Pages.Privacy.FileLock.AltImg')} src={FileLock} />
                            </Col>
                            <Col md={9} className='text-center text-md-start'>
                                <p>{t('Pages.Privacy.FileLock.Description')}</p>
                            </Col>
                        </Row>
                        <Row className='align-items-center privacy-card'>
                            <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                                <img alt={t('Pages.Privacy.CompleteData.AltImg')} src={CompleteData} />
                            </Col>
                            <Col md={9} className='text-center text-md-start'>
                                <p>{t('Pages.Privacy.CompleteData.Description')}</p>
                            </Col>
                        </Row>
                        <Row className='align-items-center privacy-card'>
                            <Col xs={12} md={3} className="image-elements pt-3 pt-md-0">
                                <img alt={t('Pages.Privacy.Chain.AltImg')} src={Chain} />
                            </Col>
                            <Col md={9} className='text-center text-md-start'>
                                <p>{t('Pages.Privacy.Chain.Description')}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
             </div>
        </>
        }
        </Section>
    </>
};

export default TheSolution;