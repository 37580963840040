import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';

import { frAnimation, enAnimation, esAnimation } from '../../../src/assets/lottie/index';

interface LottiesProps {
    lang: string | null;
}

const Lotties = ({ lang }: LottiesProps): React.JSX.Element => {

    const [animation, setAnimation] = React.useState<any>(enAnimation);
    React.useEffect(() => {
        if (lang === "fr") setAnimation(frAnimation);
        if (lang === "en") setAnimation(enAnimation);
        if (lang === "es") setAnimation(esAnimation);
    }, [lang]);

    return <Lottie  className='solution-gif' animationData = { animation } loop = { true } />;
};

export default Lotties;
