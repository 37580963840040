import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import NavMenu from './NavMenu';
import Footer from './footer/Footer';
import ScrollToTop from './ScrollToTop';
import BackAltered from './backaltered/BackAltered';

import { resources } from '../../i18n/i18n';
import Cookie from './Cookie';

const registerPageLoad = () => {

    var _paq = window._paq || [];
    _paq.push(['setCustomUrl', window.location.hash]);
    _paq.push(['setDocumentTitle', window.location.pathname]);
    if (localStorage.getItem('cookie') === "false") {
        _paq.push(['disableCookies']);
    }
    _paq.push(['trackPageView']);
};

const Layout = (): React.JSX.Element => { 

    const {t, i18n} = useTranslation();
    let location = useLocation();

    React.useEffect(() => {
        const metaDescription = document.querySelector('meta[name="description"]');
        const title = document.querySelector('title');
        if (metaDescription) {
            metaDescription.setAttribute('content', t('MetaDescription'));
        }
        if (title) {
            title.innerText = t('MetaDescription');
        }
    }, [i18n.language, t]);

    React.useEffect(() => {
        registerPageLoad();
    }, [location]);


    React.useEffect(()=>{
        let lang = "en";
        lang = localStorage.getItem("lang") === undefined ? navigator.language : localStorage.getItem("lang")!;
        if (!Object.keys(resources).includes(lang))
            lang = "en";
        i18n.changeLanguage(lang);
        localStorage.setItem("lang", i18n.language);
    }, []);

    return (
        <div className="b-alt">
            <BackAltered />
            <ScrollToTop />
            <NavMenu />
            <Outlet />
            <Cookie activate={true}/>
            <Footer />
        </div>
    );
};

export default Layout;
