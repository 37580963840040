import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

type CookieProps = {
    activate: boolean;
};

const Cookie = ({ activate }: CookieProps): React.JSX.Element => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(activate);
   
    useEffect(() => {
        const storedCookie = localStorage.getItem("cookie");
        if (storedCookie !== null) {
            setIsVisible(false);  
            hideBanner();  
        }
    }, []);

    const hideBanner = () => {
        const element = document.querySelector('.cookie-banner');
        if (element) {
            element.classList.add('d-none');
          
        }
    };
       
    const acceptCookies = () => {
        
        localStorage.setItem("cookie", "true");  
        setIsVisible(false);  
        hideBanner();      
        var _paq = window._paq || [];
        _paq.push(['rememberConsentGiven']); 
    };
       
    const refuseCookies = () => {
       localStorage.setItem("cookie", "false");  
        setIsVisible(false);  
        hideBanner();  
        var _paq = window._paq || [];
        _paq.push(['forgetConsentGiven']);  
        _paq.push(['disableCookies']);  
        deleteMatomoCookies();  
    };
      
    const deleteMatomoCookies = () => {
        const matomoCookies = ['_pk_id', '_pk_ses', '_pk_ref', '_pk_cvar'];
        const domainName = window.location.hostname;
        matomoCookies.forEach((cookieName) => {
            Cookies.remove(cookieName, { path: '/' });
            Cookies.remove(cookieName, { path: '/', domain: domainName });
        });
    };

    return (
        <div className={`cookie-banner ${!isVisible ? 'd-none' : ''}`}>
            <Row>
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <p>
                        {t('Cookie.Description')}<a href="/cookie">{t('Cookie.Link')}</a>
                    </p>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className="cookie-content-btn">
                    <button className="flowly-btn orange btn btn-primary" onClick={acceptCookies}>
                        {t('Cookie.Accept')}
                    </button>
                    <button className="flowly-btn red btn btn-primary" onClick={refuseCookies}>
                        {t('Cookie.Essential')}
                    </button>
                </Col>
            </Row>
        </div>
    );
};

export default Cookie;

