import  React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Section from '../shared/Section';
import Accordion from 'react-bootstrap/Accordion';
import Table from "react-bootstrap/Table";
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';





const Politic = (): React.JSX.Element => {
    const { t } = useTranslation();
   
    return <>
        <Section className="politic-section">
            <Row className='text-center'>
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h2 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.Title') === undefined ? "" : t('Pages.Cookies.Title') }
                    } />
                    <p dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.SubTitle') === undefined ? "" : t('Pages.Cookies.SubTitle') }
                    } />

                </Col>

            </Row>
        
                        {/*Introduction*/ }
        
            <Row >
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.IntroductionTitle') === undefined ? "" : t('Pages.Cookies.IntroductionTitle') }
                    } />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.IntroductionDescription') === undefined ? "" : t('Pages.Cookies.IntroductionDescription') }
                    } />
                </Col>
            </Row>

        
                        {/*Utilisation des cookies*/ }
        

            <Row>
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.UseCookieTitle') === undefined ? "" : t('Pages.Cookies.UseCookieTitle') }
                    } />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.UseCookieDescription') === undefined ? "" : t('Pages.Cookies.UseCookieDescription') }
                    } />
                    
                </Col>
            </Row>


            {/*Local Storage*/}

            <Row>
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.LocalStorageTitle') === undefined ? "" : t('Pages.Cookies.LocalStorageTitle') }} />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.LocalStorageDescription') === undefined ? "" : t('Pages.Cookies.LocalStorageDescription') }
                        } />
                    <ul>
                        <li> <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.LocalStorageDescription1') === undefined ? "" : t('Pages.Cookies.LocalStorageDescription1') }
                                } />
                        </li>
                        <li> <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.LocalStorageDescription2') === undefined ? "" : t('Pages.Cookies.LocalStorageDescription2') }
                        } />
                        </li>
                    </ul>
                </Col>
            </Row>


            {/*Gestioncookie*/}


            <Row>
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.ManageCookieTitle') === undefined ? "" : t('Pages.Cookies.ManageCookieTitle') }} />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.ManageCookieDescriptionText') === undefined ? "" : t('Pages.Cookies.ManageCookieDescription') }} />
                    <ul>
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.ManageCookieDescription1') === undefined ? "" : t('Pages.Cookies.ManageCookieDescription1') }} />
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.ManageCookieDescription2') === undefined ? "" : t('Pages.Cookies.ManageCookieDescription2') }} />

                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.ManageCookieDescription3') === undefined ? "" : t('Pages.Cookies.ManageCookieDescription3') }} />
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.ManageCookieDescription4') === undefined ? "" : t('Pages.Cookies.ManageCookieDescription4') }} />

                    </ul>
                        <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.ManageCookieDescriptionEnd') === undefined ? "" : t('Pages.Cookies.ManageCookieDescriptionEnd') }} />

                </Col>
            </Row>

            {/*Données Personnelles Collectées*/}

            <Row >
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.PersonnalDataTitle') === undefined ? "" : t('Pages.Cookies.PersonnalDataTitle') }
                    } />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.PersonnalDataDescription') === undefined ? "" : t('Pages.Cookies.PersonnalDataDescription') }
                    } />
                </Col>
            </Row>

            {/*Vos droits concernant vos données personnelles */}

            <Row >
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.RightDataTitle') === undefined ? "" : t('Pages.Cookies.RightDataTitle') }} />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.RightDataDescription') === undefined ? "" : t('Pages.Cookies.RightDataDescription') }} />
                    <ul>
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.RightDataDescription1') === undefined ? "" : t('Pages.Cookies.RightDataDescription1') }} />
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.RightDataDescription2') === undefined ? "" : t('Pages.Cookies.RightDataDescription2') }} />

                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.RightDataDescription3') === undefined ? "" : t('Pages.Cookies.RightDataDescription3') }} />
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.RightDataDescription4') === undefined ? "" : t('Pages.Cookies.RightDataDescription4') }} />
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.RightDataDescription5') === undefined ? "" : t('Pages.Cookies.RightDataDescription5') }} />
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.RightDataDescription6') === undefined ? "" : t('Pages.Cookies.RightDataDescription6') }} />
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.RightDataDescription7') === undefined ? "" : t('Pages.Cookies.RightDataDescription7') }} />
                        <li style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                            { __html: t('Pages.Cookies.RightDataDescription8') === undefined ? "" : t('Pages.Cookies.RightDataDescription8') }} />

                    </ul>
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.RightDataDescriptionEnd') === undefined ? "" : t('Pages.Cookies.RightDataDescriptionEnd') }} />
                </Col>
            </Row>


            {/*Liens externes*/}

            <Row >
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.ExternalLinkTitle') === undefined ? "" : t('Pages.Cookies.ExternalLinkTitle') }
                    } />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.ExternalLinkDescription') === undefined ? "" : t('Pages.Cookies.ExternalLinkDescription') }
                    } />
                </Col>
            </Row>

            {/*Sécurité des informations*/ }
            <Row >
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.SecurityTitle') === undefined ? "" : t('Pages.Cookies.SecurityTitle') }
                    } />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.SecurityDescription') === undefined ? "" : t('Pages.Cookies.SecurityDescription') }
                    } />
                </Col>
            </Row>


            {/*Divulagtion légale*/}
            <Row >
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.DisclosureTitle') === undefined ? "" : t('Pages.Cookies.DisclosureTitle') }
                    } />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.DisclosureDescription') === undefined ? "" : t('Pages.Cookies.DisclosureDescription') }
                    } />
                </Col>
            </Row>
            
            {/*Modifications de cette politique de cookies*/ }
            <Row >
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.UpdatePoliticTitle') === undefined ? "" : t('Pages.Cookies.UpdatePoliticTitle') }
                    } />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.UpdatePoliticDescription') === undefined ? "" : t('Pages.Cookies.UpdatePoliticDescription') }
                    } />
                </Col>
            </Row>

            {/*Contact*/}
            <Row >
                <Col xs={0} lg={2}></Col>
                <Col xs={12} lg={8}>
                    <h5 dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.ContactTitle') === undefined ? "" : t('Pages.Cookies.ContactTitle') }
                    } />
                    <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={
                        { __html: t('Pages.Cookies.ContactDescription') === undefined ? "" : t('Pages.Cookies.ContactDescription') }
                    } />
                </Col>
            </Row>
        </Section>
    </>
}
export default Politic