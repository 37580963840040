import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import fr from "./fr";
import es from "./es";

export const resources = {
    en: {
        translation: en
    },
    fr: {
        translation: fr
    },
    es: {
        translation: es
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "fr",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
