import React from 'react';
import Container from 'react-bootstrap/Container';

type SectionProps = {
    children : React.ReactNode
    className? : string
    style?: React.CSSProperties
    dataTrackContent?: boolean
    dataContentName?: string
}

const Section = ({ className, style, children, dataTrackContent, dataContentName } : SectionProps): React.JSX.Element => { 
    return (
        <section className={className} style={style} data-track-content={dataTrackContent} data-content-name={dataContentName}>
        <Container>
            {children}
        </Container>
      </section>
     
    );
};

export default Section;